import React from 'react'
import ArticlePreview from '../../templates/articlePreview'
import cx from 'classnames'
import {rightSide} from '../../utils/adUnits'
import {isMobile} from 'react-device-detect'
import {adDisplayDelay, delayMultiplicationFactor} from '../../utils/articleUtil'
import { addTimeout } from '../../utils/timeManager'

class ArticlePopularPosts extends React.Component {

    constructor(props) {
        super(props);
        this.className = cx(
            "hidden w-[305px] min-h-[423px] lg:flex flex-col justify-between p-4 pb-8 bg-gray-100",
            props.className
        )
    }

    // componentDidMount() {
    //     addTimeout(() => {
    //         try {
    //             googletag.cmd.push(function () {
    //                 googletag.display('div-ht_rightcol_desktop');
    //             });
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
    // }

  componentDidMount () {
    addTimeout(() => {
      rightSide('ht_article_rightcol_desktop')
    }, adDisplayDelay() * delayMultiplicationFactor(isMobile));
  }

    render() {
        /*return <div className={this.className} data-datocms-noindex style={{minWidth: '330px'}}>
            {/!*<CategoryTitle title="Popular Posts"/>*!/}
            {
                this.props.articles.map((article, index) => {
                    return <div key={`pp-${article.slug}`}>
                        <div className="popular-post-container-link">
                            <ArticlePreview key={article.slug}
                                            className="border-b-default border-gray-400 mb-2 pb-2"
                                            data={article}
                                            noAuthor={true}
                                            headingClass={"text-xl font-arial leading-snug"}
                                            noFlag={true}
                                            noBody={true}/>
                        </div>
                        {
                            {
                                1: <div className="ad-container">
                                    <div className="advertisement-text">Advertisement</div>
                                    <div id='div-pt_rightcol_desktop' style={{height: "250px"}}></div>
                                </div>
                            }[index]
                        }
                    </div>
                })
            }
        </div>*/
      return <div className={'popular-posts article-popular relative ml-3 min-w-[305px]'}>
        <div className={this.className} data-datocms-noindex>
          <div className={'mt-1 text-denim font-bold text-xl'}>
            What to read next...
          </div>
          {
            this.props.articles.slice(0, 3)
              .map((article, index) => {
                return <ArticlePreview
                  key={`top-right-${index}`} data={article}
                  className="flex w-full"
                  imageClass="mb-0 mr-2 float-left clear-both w-[70px] h-[70px]"
                  gatsbyImageClass="w-[70px] h-[70px]"
                  headingClass="font-sans font-semibold leading-snug text-lg mb-0 line-clamp l-c-3"
                  noFlag={true}
                  noBody={true}
                  noAuthor={true}
                  wrappedImg={false}
                  dateAsFlag={true}
                  imageLoading={this.props.imageLoading}
                  titleAuthorDisplayClass={'flex-col-reverse'}
                  publishedAtFormat={'mmmm dd, yyyy'}/>
              })
          }
        </div>
        {/*{this.props.rightSideAffiliate && <div className={"text-center my-4"} dangerouslySetInnerHTML={{__html: this.props.rightSideAffiliate}}/>}*/}
        <div className="ad-container my-2 min-w-[305px]">
          <div className="advertisement-text">Advertisement</div>
          <div id="ht_article_rightcol_desktop"/>
        </div>
      </div>
    }
}

export default ArticlePopularPosts;
